@import "./../styles/variables.scss";

.main {
    padding: $header-height / 2;
    padding-top: $header-height * 1.2;
    // background: red;
}

.container {
    display: grid;
    grid-template-rows: 10vh 0vh;
    grid-template-columns: 1fr;

    @media (max-width: 1300px) {
        grid-template-rows: 15vh 0vh;
    }

    @media (max-width: 760px) {
        grid-template-rows: 20vh 0vh;
    }

    @media (max-width: 630px) {
        grid-template-rows: 25vh 0vh;
    }

    @media (max-width: 610px) {
        grid-template-rows: 28vh 0vh;
    }

    @media (max-width: 540px) {
        grid-template-rows: 35vh 0vh;
    }

    @media (max-width: 500px) {
        grid-template-rows: 40vh 0vh;
    }

    @media (max-width: 470px) {
        grid-template-rows: 45vh 0vh;
    }

    @media (max-width: 410px) {
        grid-template-rows: 50vh 0vh;
    }

    @media (max-width: 380px) {
        grid-template-rows: 60vh 0vh;
    }

    @media (max-width: 360px) {
        grid-template-rows: 70vh 0vh;
    }

    @media (max-width: 320px) {
        grid-template-rows: 80vh 0vh;
    }
}

.question {
    font-weight: bold;
    font-size: 1.75rem;
}

.answer {
    font-size: 1.5rem;
}