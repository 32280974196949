@import "./../styles/variables.scss";

.main {
    padding: $header-height / 2;
    padding-top: $header-height * 1.2;
    height: 100%;
    width: 100%;
    // background: red;
}

.container1 {
    display: grid;
    grid-template-rows: 30vh 0vh;
    grid-template-columns: 1fr 2fr;

    @media (max-width: $lg) {
        grid-template-columns: repeat(1, 1fr);
    }
    
    &__frame1 {
    
        &__img {
            height: 100%;
            width: 100%;
        }
    }

    &__frame2 {
        // background-color: yellow;
    }
}

.container2 {
    // background-color: red;
    padding-top: 1rem;

    @media (max-width: $lg) {
        margin-top: 12rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 880px) {
        margin-top: 14rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 800px) {
        margin-top: 15rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 730px) {
        margin-top: 16rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 680px) {
        margin-top: 18rem;
        grid-template-columns: minmax(760px, 1fr);
    }

    @media (max-width: 630px) {
        margin-top: 19rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 600px) {
        margin-top: 21rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 570px) {
        margin-top: 22rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 530px) {
        margin-top: 24rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 500px) {
        margin-top: 26rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 470px) {
        margin-top: 27rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 440px) {
        margin-top: 30rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 425px) {
        margin-top: 31rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 410px) {
        margin-top: 33rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 390px) {
        margin-top: 35rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 380px) {
        margin-top: 38rem;
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 360px) {
        margin-top: 40rem;
        grid-template-columns: repeat(1, 1fr);
    }
}

.container3 {
    // background-color: yellow;
    display: grid;
    justify-items: center;
    grid-template-rows: 20vh 0vh;
    grid-template-columns: repeat(6, 1fr);
    
    @media (max-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 30vh 30vh;
    }

    @media (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 30vh 30vh;
    }
}


.frameMain {
    width: 9rem;
    height: 7rem;
    margin-top: 1rem;
    margin-left: 2%;
    /* background-color: blueviolet; */
}

.picMain {
    width: 7rem;
    height: 7rem;
    margin-left: 1rem;
    /* background-color: aquamarine; */

    &__title {
        /* height: 2rem; */
        width: 7rem;
        margin: 0%;
        margin-left: 1rem;
        text-align: center;
        /* background-color: aqua; */
    }
}


.info1 {
    padding: 20px;
}

.info2 {
    padding: 0 20px 20px 0;

    @media (max-width: $lg) {
        padding: 0 20px 20px 20px;
    }
}


.container4 {
    display: grid;
    flex-direction: row;
    margin-top: 40px;
    grid-template-rows: 15vh 0vh;
    grid-template-columns: 1fr 1fr;
    // background-color: red;
    justify-items: center;


    @media (max-width: 760px) {
        display: block;
        flex-direction: column;
        grid-template-columns: 1fr;
        grid-template-rows: 15vh 0vh;
    }
}

.linkCol1 {
    width: 100%;
    height: 100%;
    // background-color: yellow;
    justify-self: center;
}

.linkCol2 {
    width: 100%;
    height: 100%;
    // background-color: orange;
}

.pic1 {
    width: 50%;
    height: 100%;
    margin-left: 50%;
    // background-color: red;

    @media (max-width: 760px) {
        width: 100%;
        height: 100%;
        margin-left: 0%;
    }

    &__btn {
        position: absolute;
        width: 20%;
        height: 30vh;
        // margin-left: 50%;
        // top: 50%;
        // left: 50%;
        transform: translate(-110%, -30%);
        -ms-transform: translate(-50%, -50%);
        background-color: transparent;
        color: white;
        font-size: 16px;
        /* padding: 12px 24px; */
        border: none;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;

        @media (max-width: 760px) {
            margin-top: 50px;
            width: 100%;
            transform: translate(-100%, -5%);
        }

        @media (max-width: 600px) {
            width: 100%;
            height: 20vh;
            transform: translate(-100%, -5%);
        }

        @media (max-width: 450px) {
            width: 100%;
            height: 15vh;
            transform: translate(-100%, -25%);
        }
    }
}


.pic2 {
    width: 50%;
    height: 100%;
    margin-right: 50%;
    // background-color: yellow;

    @media (max-width: 760px) {
        margin-right: 0%;
        width: 100%;
        height: 100%;
        margin-left: 0%;
    }

    &__btn {
        position: absolute;
        width: 20%;
        height: 30vh;
        // margin-left: 50%;
        // top: 50%;
        // left: 50%;
        transform: translate(-230%, -30%);
        -ms-transform: translate(-50%, -50%);
        background-color: transparent;
        color: white;
        font-size: 16px;
        /* padding: 12px 24px; */
        border: none;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;

        @media (max-width: 760px) {
            margin-top: 50px;
            width: 100%;
            transform: translate(-100%, -5%);
        }

        @media (max-width: 600px) {
            width: 100%;
            height: 20vh;
            transform: translate(-100%, -5%);
        }

        @media (max-width: 450px) {
            width: 100%;
            height: 15vh;
            transform: translate(-100%, -25%);
        }
    }
}
