@import "./../styles/variables.scss";

.main {
    padding-top: $header-height * 1.2;
}

.container1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // background: red;
    justify-content: center;
}

.container2 {
    display: grid;
    grid-template-rows: 40vh 100vh;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $lg) {
        grid-template-columns: repeat(1, 1fr);
    }

    // background-color: orange;

    &__col1 {
        // background-color: blue;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media (max-width: $lg) {
        display:flex;
        flex-direction: column;
    }

    &__col2 {
        // background-color: purple;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @media (max-width: $lg) {
            margin-top: 10rem;
            display:flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__pic {
        width: 60%;
        height: 250vh;

        @media (max-width: 600px) {
            width: 70%;
            height: 180vh;
        }
    }
}