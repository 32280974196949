@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

$ubuntu-font: "Ubuntu", sans-serif;

$bg: red;//rgb(255, 255, 255);
$bg2: #e11208; // rgb(0, 0, 0);
$text: black;
$textTitle: white; //rgb(15, 15, 15);
$primary: #e11208; //rgb(22, 210, 247);

$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$header-height: 72px;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl1: 1280px;
$xl2: 1538px;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: $md) {
            @content;
        }
    }
}