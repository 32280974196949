@import "./../styles/variables.scss";

.main {
    padding: $header-height / 2;
    padding-top: $header-height * 1.2;
    height: 100%;
    width: 100%;
    // background: red;
}

.container {
    display:flex;
    flex-direction: row;
    align-items: center;
    grid-template-rows: 20vh 0vh;
    grid-template-columns: 1fr;
    margin-top: 10%;

    @media (max-width: 770px) {
        display: grid;
        flex-direction: column;
    }
}

.info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 770px) {
        margin-top: 80%;
    }

    @media (max-width: 600px) {
        margin-top: 90%;
    }

    @media (max-width: 500px) {
        margin-top: 105%;
    }

    @media (max-width: 450px) {
        margin-top: 120%;
    }

    @media (max-width: 400px) {
        margin-top: 140%;
    }

    @media (max-width: 380px) {
        margin-top: 160%;
    }
}

.picAboutUs {
    width: 15rem;
    height: 15rem;
    border-radius: 40rem;

    @media (max-width: 770px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-self: center;
    }
}

.textTitleAboutUs {
    font-weight: bold;
    padding-left: 1rem;
}

.textAboutUs {
    padding-left: 1rem;
}