@media screen and (min-width: 800px) {
    .frameAboutUs {
        display: flex;
        flex-direction: row;
        padding: 10rem;
    }
    
    .picAboutUs {
        width: 10rem;
        height: 10rem;
        border-radius: 40rem;
    }
    
    .textTitleAboutUs {
        font-weight: bold;
        padding-left: 1rem;
    }
    
    .textAboutUs {
        padding-left: 1rem;
    }
}

