.main {
    height: 65rem;
}

.header {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: green;
    /* padding-left: 5%; */
}

.headerPic {
    height: 4rem;
    width: 10rem;
}


.titleNav {
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
}

.titleNavBold {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
}

.titleNavSelected {
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
}


@media screen and (min-width: 1100px) and (max-width: 1230px) {
    .header {
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        background-color: green;
        /* padding-left: 5%; */
    }
}


@media screen and (min-width: 1020px) and (max-width: 1100px) {
    .header {
        width: 105%;
        height: 4rem;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        background-color: green;
        /* padding-left: 5%; */
    }
}

@media screen and (min-width: 8000px) and (max-width: 1020px) {
    .header {
        width: 115%;
        height: 4rem;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        background-color: green;
        /* padding-left: 5%; */
    }
}